<template>
	<div class="download">
		<div class="content clearfix">
			<img src="../assets/img/logo2.png" alt="" class="logo" />
			<img class="fl phone" src="../assets/img/phone.png" alt="">
			<div class="fl text-box">
				<div class="top-text">Book Summaries Daily</div>
				<div class="text-g">Learn before/after reading</div>
				<div class="text-b">Download the app to unlock more features.</div>
				<div class="list">
					<div class="list-item" v-for="item in list" :key="item.id">{{item.text}}</div>
				</div>
				<div class="code-list">
					<div class="code-list-item" v-for="item in codeList" :key="item.id">
						<img :src="hostname+item.img" alt="" class="code-list-item-code">
						<img :src="item.icon" alt="" class="code-list-item-icon">
					</div>
				</div>
			</div>
		</div>
		<footer-view></footer-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
					id: 1,
					text: '10,000+Bestsellers'
				}, {
					id: 2,
					text: '36+ languages'
				}, {
					id: 3,
					text: '300+ Celebrity Picks'
				}, {
					id: 4,
					text: 'Chat Books(Beta).'
				}],
				codeList: [{
					id: 1,
					img: '/HeardLyIOS.jpg',
					icon: require('../assets/img/icon-ios.png')
				}, {
					id: 2,
					img: '/HeardLyAndroid.jpg',
					icon: require('../assets/img/icon-and.png')
				}],
				hostname: ''
			}
		},
		methods: {
			toIndex() {
				this.$router.push({
					path: '/'
				})
			}
		},
		mounted() {
			const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
			if (isiOS) {
				// iOS 设备的 URL 
				window.location.href = 'https://apps.apple.com/app/heardly-lesstimemoreknowledge/id6473803023';
			}
			this.hostname = 'http://' + window.location.hostname
		}
	}
</script>

<style lang="less" scoped>
	.download {
		background: #F7F7F7;
		// padding-top: 87px;
		font-family: 'C-B';

		.content {
			padding-top: 1.5rem;
			padding-bottom: 3.75rem;
			color: #1A1C2E;
			// height: 13.75rem;
			background: url(../assets/img/bg2.png) no-repeat;
			background-size: cover;

			.logo {
				display: block;
				margin-left: 10rem;
				height: 48px;
				width: auto;
				margin-bottom: 4rem;
				cursor: pointer;
			}

			.phone {
				width: 19rem;
				margin-left: 10rem;
			}

			.text-box {
				margin-left: 4.166667rem;
				margin-top: 0.333333rem;

				.top-text {
					font-size: 2.166667rem;
					color: #757879;
					background: linear-gradient(145deg, #040000 22%, #931513 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				.text-g {
					font-size: 1.75rem;
					color: #B5C1BC;
					margin-top: 0.166667rem;
					margin-bottom: 1.25rem;
				}

				.text-b {
					color: #3756C2;
					font-size: 1.083333rem;
					margin-bottom: 1rem;
				}

				.list {
					width: 22rem;

					.list-item {
						margin-left: 0.75rem;
						margin-bottom: 0.75rem;
						display: inline-block;
						font-size: 0.833333rem;
						color: #3756C2;
						width: 10.5rem;
						height: 3rem;
						line-height: 3rem;
						background: url(../assets/img/btn.png) no-repeat left top;
						background-size: 100% 100%;
						text-align: center;
					}

					.list-item:nth-child(2n-1) {
						margin-left: 0;
					}
				}

				.code-list {
					margin-top: 0.375rem;

					.code-list-item {
						width: 5.666667rem;
						height: 5.666667rem;
						position: relative;
						border: 0.083333rem solid #000;
						display: inline-block;
						margin-right: 1.416667rem;

						.code-list-item-code {
							width: 100%;
							height: 100%;
							padding: 0.25rem;
							box-sizing: border-box;
						}

						.code-list-item-icon {
							width: 1.333333rem;
							height: 1.333333rem;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translateX(-50%) translateY(-50%);
						}
					}
				}
			}
		}
	}

	@media (max-width:960px) {
		.logo {
			margin-left: 2rem !important;
		}

		.phone {
			margin-left: 6.5rem !important;
			margin-bottom: 1.666667rem;
		}
	}
</style>