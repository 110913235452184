import { render, staticRenderFns } from "./dl.vue?vue&type=template&id=5549523a&scoped=true&"
import script from "./dl.vue?vue&type=script&lang=js&"
export * from "./dl.vue?vue&type=script&lang=js&"
import style0 from "./dl.vue?vue&type=style&index=0&id=5549523a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5549523a",
  null
  
)

export default component.exports